import React, { Component } from 'react'

import Layout from '../Components/layout.js'
import SEO from '../Components/seo.js'
import Pictures from '../Components/Picture.jsx'
import TextWithPicture from '../Components/TextWithPicture.jsx'
import TextTitle from '../Components/TextTitle.jsx'
import Row from '../Components/Rows.jsx'
import TextTiles from '../Components/TextTiles.jsx'
import TextPictureWithPictureTiles from '../Components/TextPictureWithPictureTiles.jsx'

class Products extends Component {
  render() {
    return (
      <Layout>
        <SEO title='Label Finishes' />
        <Pictures splash src={'MaterialSplash4'} />

        <Row noPaddingBottom>
          <TextTitle title='Cut Types' />
        </Row>
        <Row>
          <TextTiles
            tiles={[
              {
                title: 'Cold Cut',
                content: 'Cold cut works well for materials that do not fray and cannot be sealed.'
              },
              {
                title: 'Hot Cut',
                content: 'Heat sealing, or hot cut, seals materials such as satin which would normally fray and fall apart if only cold cutted. Heating the blade up to 800 degrees Celcius melts the edge of the material leaving a hairless seam.'
              },
              {
                title: 'UltraSonic',
                content: 'Ultrasonic cut, or sonic cut, seals material in a similar way to hot cut but at a microscopic level. It uses finely-tuned vibrations that generate heat only at the cutting point. Doing so maintains the soft feel on the edge of the label and results in a higher-quality feeling product.'
              }
            ]}
          />
        </Row>

        <Row noPadding>
          <TextTitle title='Fold Types' />
        </Row>
        <Row noPaddingBottom>
          <TextWithPicture
            below
            alignPicture={'left'}
            pictureSize={'smaller'}
            imgSrc={'OneTeeStyledLabel'}
            title={'Center Fold'}
            textContent={'Center folds are labels folded in the center and the ends are usually sewn together.'}
          />
        </Row>
        <Row>
          <TextPictureWithPictureTiles
            below
            alignPicture={'right'}
            pictureSize={'smaller'}
            imgSrc={'EndFoldFour4'}
            title={'End Fold'}
            textContent={'End Folds are usually rectangular in length with both ends folded over. This type of fold allows you to add a branding without sewing it onto the seam. Great for brands, towels, and clothing.'}
            pictures={[
              { imgSrc: 'EndFoldOne4' },
              { imgSrc: 'EndFoldTwoLabels' },
              { imgSrc: 'EndFoldThree4' },
              { imgSrc: 'EndFoldFour5' },
              { imgSrc: 'EndFoldThreeLabels' }, 
              { imgSrc: 'EndFoldThree2' }
            ]}
            cols={6}
          />
        </Row>
        <Row noPaddingTop>
          <TextWithPicture
            below
            alignPicture={'left'}
            pictureSize={'smaller'}
            imgSrc={'AlstyleLabels'}
            title={'Book Fold'}
            textContent={'Book Folds are folded along with the center with both sides available for content, acting like a book. The top side is usually shorter than the bottom side.'}
          />
        </Row>
      </Layout>
    )
  }
}

export default Products